<template>
    <b-container fluid>
      <b-row class="mt-5" style="padding-left: 150px; padding-right: 150px;">
        <b-col cols="12" class="d-flex justify-content-center align-item-center mb-5">
          <div class="logo-wrapper ">
            <img src="../../../assets/img/BAU_Logo.png" height="130" />
          </div>
        </b-col>
        <b-col class="d-flex justify-content-center align-item-center mb-5">
          <h2 class="text-center">{{ $t('room_reservation_system') }}</h2>
        </b-col>
        <b-col cols="12">
          <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
            <ValidationObserver ref="formModalValidate">
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group :label="$t('start_date')+' / '+$t('end_date')">
                    <ValidationProvider name="date" rules="required" v-slot="{ valid, errors }">
                      <v-date-picker
                        v-model="datatable.queryParams.filter.date"
                        is-range
                        :locale="'en'"
                        :model-config="{ type: 'string', mask: 'MM/DD/YYYY' }"
                      >
                        <template v-slot="{ inputValue, inputEvents }">
                          <div
                            class="border rounded-sm p-2 p-md-3 d-flex align-items-center"
                            :class="errors[0] ? 'box-border-color':''"
                          >
                            <div class="flex-grow-1">
                              <b-form-group class="m-0 mr-2">
                                <input
                                  :value="inputValue.start"
                                  v-on="inputEvents.start"
                                  :placeholder="$t('start_date')"
                                  class="form-control"
                                />
                              </b-form-group>
                            </div>
                            <div class="flex-grow-1">
                              <b-form-group class="m-0 ml-2">
                                <input
                                  :value="inputValue.end"
                                  v-on="inputEvents.end"
                                  :placeholder="$t('end_date')"
                                  class="form-control"
                                />
                              </b-form-group>
                            </div>
                          </div>
                          <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </template>
                      </v-date-picker>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" class="pl-md-4 mb-3"> <!-- Sol tarafta boşluk -->
                  <ValidationProvider name="gender" rules="required" v-slot="{ valid, errors }">
                    <b-form-group :label="$t('gender')">
                      <gender-selectbox v-model="datatable.queryParams.filter.gender" :validate-error="errors[0]" />
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
            </ValidationObserver>
          </datatable-filter>
        </b-col>
        <b-col cols="12">
        <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            />
        </b-col>
      </b-row>
      <CommonModal ref="updateFormModal" size="lg" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('add_reservation').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <update-form 
                        v-if ="formProcess == 'reservationModal'"
                        @verificationSuccess="handleVerificationSuccess"
                        @emailUpdated="reservationEmail = $event"
                    />
                </template>
            </CommonModal>
    </b-container>
  </template>
  
  

<script>
    // Component
    import Datatable from '@/components/datatable/Datatable'
    import DatatableFilter from '@/components/datatable/DatatableFilter'
    import GenderSelectbox from "@/components/interactive-fields/GenderSelectbox.vue";
    import CommonModal from '@/components/elements/CommonModal'

    // Services
    import reservationSystemFormService from '@/services/reservationSystemFormService'

    // Pages
    import UpdateForm from '@/modules/reservationSystemForm/pages/UpdateForm'

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import qs from 'qs'
    

    export default {
        props: {
            rsRefresh: {
                type: Boolean,
                default: false
            }
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            Datatable,
            DatatableFilter,
            GenderSelectbox,
            CommonModal,
            UpdateForm,
        },
        metaInfo() {
            return {
                title: this.$t('room_reservation_system')
            }
        },
        data() {
            return {
                datatable: {
                    isLoading: false,
                    columns: [
                        {
                            label: '',
                            field: 'buttons',
                            html: true,
                            sortable: false,
                            tdClass: 'p-0 text-center w-40 align-middle',
                            buttons: [
                                {
                                    text: this.$t('add_reservation'),
                                    class: 'ri-calendar-check-line',
                                    hidden: false,
                                    permissions: "housingbed_update",
                                    callback: (row) => {
                                        this.updateFormShow(row);
                                    }
                                }
                            ]
                        },
                        {
                            label: this.$t('housing_building_name'),
                            field: 'housing_building_name',
                            sortable: true
                        },
                        {
                            label: this.$t('housing_room_name'),
                            field: 'housing_room_name',
                            sortable: true
                        },
                        {
                            label: this.$t('type'),
                            field: 'type_name',
                            sortable: true
                        },
                        {
                            label: this.$t('floor'),
                            field: 'housing_floor',
                            sortable: true
                        },
                        {
                            label: this.$t('price'),
                            field: 'fee',
                            sortable: true
                        },
                    ],
                    rows: [],
                    total: 0,
                    filterStatus: true,
                    showTable: false,
                    queryParams: {
                        filter: {
                            check_in_date: null,
                            check_out_date: null,
                            gender: null
                        },
                        sort: 'id',
                        page: 1,
                        limit: 20
                    }
                },
                formLoading: false,
                formId: null,
                formProcess: null,
                reservationEmail: '',
                selectedReservation: {}
            }
        },
        watch: {
            rsRefresh: {
                handler: function(val) {
                    if(val){
                        this.getRows()
                    }
                }
            }
        },
        methods: {
            filter() {
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            filterClear() {
                this.datatable.queryParams.filter = {
                    check_in_date: null,
                    check_out_date: null,
                    gender: null
                }
            
            },
            onPageChange(params) {
                this.datatable.queryParams.page = params.currentPage;
                this.getRows();
            },
            onPerPageChange(params) {
                this.datatable.queryParams.limit = params.currentPerPage;
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            onSortChange(params) {
                const sortType = params[0].type == 'desc' ? '-' : '';
                this.datatable.queryParams.sort = sortType + params[0].field;
                this.getRows();
            },
            async getRows() {
                const isValid = await this.$refs.formModalValidate.validate();

                if (isValid) {
                    this.datatable.showTable = true
                    this.datatable.isLoading = true

                    // Date
                    if (this.datatable.queryParams.filter.date.start && this.datatable.queryParams.filter.date.end) {
                       

                        this.datatable.queryParams.filter.check_in_date =this.datatable.queryParams.filter.date.start

                        this.datatable.queryParams.filter.check_out_date = this.datatable.queryParams.filter.date.end 


                        delete this.datatable.queryParams.filter.date         
                    }


                    const config = {
                        params: {
                          ...this.datatable.queryParams
                        },
                        paramsSerializer: (params) => qs.stringify(params, {encode: false})
                    };

                    return reservationSystemFormService.getAll(config)
                        .then((response) => {
                            this.datatable.rows = response.data.data;
                            this.datatable.total = response.data.pagination.total;
                        })
                        .catch((e) => {
                            this.showErrors(e);
                        })
                        .finally(() => {
                            this.datatable.isLoading = false;
                        }
                    );
                }
            },

            // Clear
            formClear() {
                this.formId = null
                this.formProcess = null
                this.$store.dispatch('reservationSystemForm/deleteFormData');


            },

            // Update
            updateFormShow(selectedRow) {
                let data = {
                    ...selectedRow,
                    ...this.datatable.queryParams.filter
                }
                this.formProcess = 'reservationModal';
                this.$store.dispatch('reservationSystemForm/saveFormData', data);
                this.selectedReservation = selectedRow;
                this.$refs.updateFormModal.$refs.commonModal.show();
                
            },
            handleVerificationSuccess() {
                this.formProcess = 'reservationModal';
                this.$refs.updateFormModal.$refs.commonModal.hide(); 
                this.$router.push('/reservation/system/form/send-email');
            },
        }
    }
</script>